<template>
  <AppBar>
    <div class="btnBox">
      <el-button type="info" round size="small" @click="screening(0)">全部</el-button>
      <el-button type="danger" round size="small" @click="screening(1)">设计未通过</el-button>
    </div>
    <ListData
      isApproval
      :tableData="tableData"
      isBtn="1"
      @RefreshData="RefreshData"
    ></ListData>
    <el-pagination
      style="margin-top: 20px;"
      :hide-on-single-page="true"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="20"
      background
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
  </AppBar>
</template>
<style lang="less" scoped>
.btnBox {
  margin-bottom: 20px;
  text-align: right;
  button {
    padding: 8px 25px;
    font-size: 14px;
  }
}

</style>
<script>
import AppBar from "../../components/AppBar";
import ListData from "../../components/ListData";
export default {
  data() {
    return {
      tableData: [],
      Mytaskparams: {
        page: 1,
        state: 0
      },
      currentPage: 1,
      total: 0,
    };
  },
  methods: {
    // 分页
    handleCurrentChange(page) {
      this.tableData = [];
      this.Mytaskparams.page = page;
      this.$common.getInterface("/api/v1.projects/getTasks", this.Mytaskparams, this, res => {
        // console.log(res);
        // id_headman
        let id = sessionStorage.getItem("id");
        res.data.data.forEach(item => {
          let nowDate =
            new Date().getFullYear() +
            "/" +
            (new Date().getMonth() + 1) +
            "/" +
            new Date().getDate() + " 00:00:00";
          if (
            (item.id_workers.indexOf(id) != -1 &&
              new Date(item.id_workers_date.replace(/-/g, '/')).getTime() >=
                new Date(nowDate).getTime()) && new Date(item.indate.replace(/-/g, '/')).getTime() >=
                new Date(nowDate).getTime()
          ) {
            this.tableData.push(item);
          }
        });
      });
    },
    // 筛选
    screening(screen) {
      this.Mytaskparams.state = screen;
      this.Mytaskparams.page = 1;
      this.currentPage = 1;
      this.$common.getInterface(
        "/api/v1.projects/getTasks",
        { state: screen },
        this,
        (res) => {
            this.tableData = [];
            let id = sessionStorage.getItem("id");
            res.data.data.forEach(item => {
              let nowDate =
                new Date().getFullYear() +
                "/" +
                (new Date().getMonth() + 1) +
                "/" +
                new Date().getDate() + " 00:00:00";
              if (
                (item.id_workers.indexOf(id) != -1 &&
                  new Date(item.id_workers_date.replace(/-/g, '/')).getTime() >=
                    new Date(nowDate).getTime()) && new Date(item.indate.replace(/-/g, '/')).getTime() >=
                    new Date(nowDate).getTime()
              ) {
                this.tableData.push(item);
              }
            })
            this.total = this.tableData.length;
          console.log(res);
        }
      );
    },
    RefreshData() {
      this.tableData = [];
      this.$common.getInterface("/api/v1.projects/getTasks", {}, this, res => {
        // console.log(res);
        // id_headman
        let id = sessionStorage.getItem("id");
        res.data.data.forEach(item => {
          let nowDate =
            new Date().getFullYear() +
            "/" +
            (new Date().getMonth() + 1) +
            "/" +
            new Date().getDate() + " 00:00:00";
          if (
            (item.id_workers.indexOf(id) != -1 &&
              new Date(item.id_workers_date.replace(/-/g, '/')).getTime() >=
                new Date(nowDate).getTime()) && new Date(item.indate.replace(/-/g, '/')).getTime() >=
                new Date(nowDate).getTime()
          ) {
            this.tableData.push(item);
          }
        });
        this.total = this.tableData.length;
      });
    }
  },
  created() {
    this.$common.getInterface("/api/v1.projects/getTasks", { state: 0 }, this, res => {
      // console.log(res);
      // id_headman
      // 渲染
      this.tableData = [];
      let id = sessionStorage.getItem("id");
      res.data.data.forEach(item => {
          let nowDate =
            new Date().getFullYear() +
            "/" +
            (new Date().getMonth() + 1) +
            "/" +
            new Date().getDate() + " 00:00:00";
          if (
            (item.id_workers.indexOf(id) != -1 &&
              new Date(item.id_workers_date.replace(/-/g, '/')).getTime() >=
                new Date(nowDate).getTime()) && new Date(item.indate.replace(/-/g, '/')).getTime() >=
                new Date(nowDate).getTime()
          ) {
            this.tableData.push(item);
          }
      });
      this.total = this.tableData.length >= 20 ? res.data.data.length : this.tableData.length;

      // this.tableData = res.data.data;
    });
     
     
   
  },
  components: {
    AppBar,
    ListData
  }
};
</script>
